import { Link } from "gatsby";
import React from "react";
import SEO from "../SEO";
import styled from "styled-components";

//import Telefon from "../../assets/img/telefonW.svg";

const Platba404Inner = styled.div`
	height: calc(100vh - 100px);

	h1 {
		font-family: NY-Semibold;
		font-size: 25px;
		color: #000000;
		line-height: 34px;
		width: calc(100% - 50px);
		max-width: 350px;
		margin: 0 auto;
		padding-top: 80px;
		margin-bottom: 30px;
	}

	p {
		font-family: Visuelt-Regular;
		font-size: 16px;
		color: #000000;
		line-height: 25px;
		width: calc(100% - 50px);
		max-width: 350px;
		margin: 0 auto;
		margin-bottom: 40px;
	}

	.zpetButton {
		font-family: Visuelt-Medium;
		font-size: 18px;
		color: #000000;
		text-align: center;
		background: rgb(255, 238, 98);
		text-decoration: none;
		width: 100%;
		display: block;
		width: calc(100% - 50px);
		max-width: 350px;
		margin: 0 auto;
		border-radius: 5px;
		padding: 15px 0;
	}
`;


const Platba404 = () => {

	return (
		<>
			<SEO
				title="Stránka nenalezena"
				description="Bohužel, stránka, kterou hledáte neexistuje. Zkuste se vrátit zpět nebo přejděte na úvodní stránku webu."
				noindex={true}
			/>
			<section>
				<Platba404Inner>
					<h1>404 - stránka nenalezena</h1>
					<p>Bohužel, stránka, kterou hledáte neexistuje. Zkuste se vrátit zpět nebo přejděte na úvodní stránku webu.</p>
					<Link className="zpetButton" to="/">Zpět na hlavní stránku</Link>
				</Platba404Inner>
			</section>
		</>
	);
}

export default Platba404;
