import SEO from "../SEO";
import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "gatsby";
import styled from "styled-components";
import EmailPoPlatbe from "../emails/EmailPoPlatbe";
import { renderToString } from 'react-dom/server';

//import Telefon from "../../assets/img/telefonW.svg";
import Success from "../../assets/img/success.svg";
import Cancel from "../../assets/img/cancel.svg";
import LoadingKruh from "../../assets/img/loadingKruh.svg";

const PlatbaResponseInner = styled.div`
	height: calc(100vh - 100px);

	div {
		padding-top: 100px;
	}

	img {
		display: block;
		margin-top: 70px;
		margin: 0 auto;
	}

	h1 {
		font-family: Visuelt-Medium;
		font-size: 25px;
		color: #000000;
		text-align: center;
		line-height: 34.82px;
		width: calc(100% - 50px);
		max-width: 600px;
		margin: 0 auto;
		margin-top: 30px;
	}

	p {
		font-family: Visuelt-Regular;
    font-size: 18px;
    color: #333333;
    line-height: 28px;
		width: calc(100% - 50px);
		max-width: 600px;
		margin: 0 auto;
		margin-top: 20px;
		text-align: center;
	}

	a {
		font-family: Visuelt-Medium;
		font-size: 18px;
		color: #000000;
		text-align: center;
		background: rgb(255, 238, 98);
		border-radius: 5px;
		border: 0;
		width: calc(100% - 50px);
		max-width: 280px;
		padding: 13px 0;
		margin-top: 30px;
		cursor: pointer;
		margin: 0 auto;
		margin-top: 30px;
		display: block;
		text-decoration: none;

		&:hover {
			background: #EFD83E;
		}
	}
`;

const PlatbaResponse = ({ id, response }) => {

	const [responseState, setResponseState] = useState("");

	
	useEffect(() => {

		// nastavení správné backend url

		let backendUrl = "";

		if (process.env.NODE_ENV === 'development') {
			backendUrl = 'http://localhost:1337'
		}
		
		if (process.env.NODE_ENV === 'production') {
			backendUrl = 'https://goodbye-backend.herokuapp.com'
		}


		// testujeme jestli jsou parametry v URL v pohodičce
		if (response === "success" || response === "cancel") {

			// napojujeme se na API
			axios.post(`${backendUrl}/auth/local`, {
				identifier: 'app@goodbye.cz',
				password: 'Good.Bye123',
			})
				.then((res) => {
					// testujeme jestli ID vůbec existuje a ukádáme to do order state
					const jwt = res.data.jwt;
					axios.get(`${backendUrl}/orders/${id}`, {
						headers: {
							Authorization: `Bearer ${jwt}`
						},
					})
						.then((res) => {
							if (res.data) {
								//setOrder(res.data);

								// pokud je to success stránka tak to změní status v databázi

								if (response === "success") {
									setResponseState("success");

									if (!res.data.isPaid) {
										console.log("Zaplaceno");
										// email pro nas
										axios.post(`${backendUrl}/poslimail`, {
											"to": 'podpora@goodbye.cz',
											"from": 'podpora@goodbye.cz',
											"subject": `Zaplaceno - ${res.data.name}`,
											"text": `Zaplaceno - ${res.data.name}`,
											"html": `<h1>Zaplaceno - ${res.data.name}</h1><br><pre>${JSON.stringify(res.data, null, 2)}</pre>`,
										},
										{
											headers: {
												"Authorization": `Bearer ${jwt}`,
												"Content-Type": "application/json"
											},
										});

										// email pro zákazníka
										axios.post(`${backendUrl}/poslimail`, {
											"to": res.data.email,
											"from": 'podpora@goodbye.cz',
											"subject": `Děkujeme za objednávku - Goodbye.cz`,
											"text": `Děkujeme za Vaši objednávku. Co nejdříve Vás budeme kontaktovat, abychom Vás informovali o termínu přepravy Vašeho blízkého k nám. V případě jakýchkoli otázek jsme tu pro Vás na zákaznické lince 315 558 136. Děkujeme a přejeme hodně sil, tým Goodbye`,
											"html": renderToString(<EmailPoPlatbe></EmailPoPlatbe>),
										},
										{
											headers: {
												"Authorization": `Bearer ${jwt}`,
												"Content-Type": "application/json"
											},
										}).then();

										// změna zaplacení v databázi

										axios.put(`${backendUrl}/orders/${id}`, {
											"isPaid": true
										},
										{
											headers: {
												Authorization: `Bearer ${jwt}`
											},
										}).then(res => res);
									}

									else {
										console.log("Již zaplaceno");
									}
								}

								// pokud je to cancel stránka tak se nic nezmění

								if (response === "cancel") {
									setResponseState("cancel");
								}
							}
						})
						.catch((err) => {
							console.log(err);
							if (err.response.status === 404) {
								navigate("/objednavka/404/");
							}
						});
				});
		}

		else {
			navigate("/objednavka/404/");
		}
		
	}, [id, response]);

	return (
		<>
			{(responseState === "") ?
				<SEO title="Načítání" description="Načítání…" />
			: null}

			{(responseState === "success") ?
				<SEO title="Zaplaceno" description="Děkujeme. Platba proběhla v pořádku." />
			: null}

			{(responseState === "cancel") ?
				<SEO title="Něco se nepodařilo" description="Něco se nepodařilo. Zkuste to prosím znovu nebo nás kontaktujte." />
			: null}


			<section>
				<PlatbaResponseInner>
					{(responseState === "") ?
						<div>
							<img src={LoadingKruh} alt="Načítání…" />
							<h1>Načítání…</h1>
						</div>
					: null}

					{(responseState === "success") ?
						<div>
							<img src={Success} alt="Platba proběhla v pořádku." />
							<h1>Děkujeme. Platba proběhla v pořádku.</h1>
							<p>Brzy vás budeme kontaktovat.</p>
						</div>
					: null}

					{(responseState === "cancel") ?
						<div>
							<img src={Cancel} alt="Něco se nepodařilo" />
							<h1>Něco se nepodařilo. Zkuste to prosím znovu nebo nás kontaktujte.</h1>
							<Link to={`/objednavka/${id}`}>Zkusit znovu</Link>							
						</div>
					: null}
				</PlatbaResponseInner>
			</section>
		</>
	);
}

export default PlatbaResponse;
