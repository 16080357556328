import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import PlatbaPage from "../components/platba/PlatbaPage";
import Platba404 from "../components/platba/Platba404";
import PlatbaResponse from "../components/platba/PlatbaResponse";
import ObjednavkaForm from "../components/platba/ObjednavkaForm";
import styled from "styled-components";
import ObjednavkaLoading from "../components/platba/ObjednavkaLoading";

const PlatbaInner = styled.div`
	background: #fff;

	.loadingAnimationWrap {
		height: calc(100vh);
		position: fixed;
		width: 100%;
		background: rgb(241, 242, 243);

		img {
			margin: 0 auto;
			display: block;
			padding-top: 100px;
		}
	}
`;

const Platba = () => {

	return (
		<Layout simpleHeader>
			<PlatbaInner>
				<Router>
					<ObjednavkaForm path="/objednavka/formular/" />
					<PlatbaPage path="/objednavka/:id/" />
					<ObjednavkaLoading path="/objednavka/" />
					<PlatbaResponse path="/objednavka/:id/:response/" />
					<Platba404 path="/objednavka/404/" />
				</Router>
			</PlatbaInner>
		</Layout>
	);
}

export default Platba;
