import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { loadStripe } from "@stripe/stripe-js"
import SEO from "../SEO";
import axios from "axios";
import styled from "styled-components";
import SmallSupportRef from "../SmallSupportRef";

//import Telefon from "../../assets/img/telefonW.svg";
import ApplePay from "../../assets/img/applePay.svg";
import GooglePay from "../../assets/img/googlePay.png";
import Mastercard from "../../assets/img/mastercard.svg";
//import PayPal from "../../assets/img/paypal.svg";
import Visa from "../../assets/img/visa.svg";
import Loading from "../../assets/img/loading.svg";
import Check from "../../assets/img/check.svg";
import CheckCircle from "../../assets/img/checkCircle.svg";


const PlatbaPageInner = styled.div`
	.jumbotron {
		padding-top: 30px;
		padding-bottom: 30px;
		background: #F7F7F7;

		@media (min-width: 600px) {
			padding-top: 66px;
			padding-bottom: 66px;
		}

		h1 {
			font-family: NY-Medium;
			font-size: 25px;
			color: #000000;
			line-height: 34px;
			margin: 0 auto;
			width: calc(100% - 50px);
			margin-bottom: 25px;
			max-width: 600px;

			@media (min-width: 900px) {
				font-family: NY-Medium;
				font-size: 32px;
				color: #000000;
				max-width: 1150px;
				width: calc(100% - 100px);
				line-height: 38px;
			}
		}

		p {
			font-family: Visuelt-Regular;
			font-size: 16px;
			color: #4F4F4F;
			line-height: 25px;
			margin: 0 auto;
			width: calc(100% - 50px);
			max-width: 600px;

			@media (min-width: 900px) {
				width: calc(100% - 100px);
				max-width: 1150px;
			}
		}
	}

	.body {
		@media (min-width: 900px) {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			width: calc(100% - 100px);
			margin: 70px auto;
			max-width: 1150px;
		}

		.right {
			@media (min-width: 900px) {
				order: 1;
				margin: 0;
				width: 340px;
			}

			.nabidka {
				background: #FFFFFF;
				margin: 0 auto;
				margin-bottom: 50px;
				margin-top: 40px;
				max-width: 600px;
				width: calc(100% - 50px);

				@media (min-width: 900px) {
					margin-top: 0px;
					max-width: none;
					width: 100%;
				}

				h3 {
					font-family: Visuelt-Medium;
					font-size: 22px;
					color: #000000;
					line-height: 24px;
					margin-bottom: 10px;
				}

				.jmeno {
					font-family: Visuelt-Regular;
					font-size: 18px;
					color: #5E5E5E;
					margin-bottom: 30px;
					line-height: 24px;
					display: block;
				}

				ul {
					border-bottom: 2px solid #000000;
					padding-bottom: 15px;

					li {
						list-style: none;
						margin-bottom: 15px;
						display: flex;

						img {
							display: block;
						}

						span {
							display: block;
							margin-left: 14px;
						}
					}
				}

				.cena {
					font-family: Visuelt-Bold;
					font-size: 23px;
					color: #000000;
					margin-top: 25px;
					display: block;

					span {
						font-family: Visuelt-Bold;
						font-size: 18px;
					}
				}
			}
		}

		.left {
			max-width: 600px;
			width: calc(100% - 20px);
			margin: 0 auto;

			@media (min-width: 900px) {
				width: calc(100% - 400px);
				margin: 0;
				max-width: 600px;
			}

			.bublina {
				background: #FFFFFF;
				border: 1px solid #D2D2D2;
				border-radius: 5px;
				padding-top: 25px;
				padding-bottom: 25px;

				h2 {
					font-family: Visuelt-Medium;
					font-size: 22px;
					color: #000000;
					display: block;
					width: calc(100% - 50px);
					margin: 0 auto;
					line-height: 25px;
					margin-bottom: 25px;
				}

				p {
					font-family: Visuelt-Regular;
					font-size: 16px;
					color: #000000;
					line-height: 25px;
				}
			}

			.karta {
				margin-bottom: 30px;

				button {
					font-family: Visuelt-Medium;
					font-size: 18px;
					color: #000000;
					text-align: center;
					background: rgb(255, 238, 98);
					border-radius: 5px;
					border: 0;
					width: calc(100% - 50px);
					padding: 13px 0;
					margin-top: 30px;
					cursor: pointer;
					margin: 0 auto;
					margin-bottom: 15px;
					display: block;

					&:hover {
						background: #EFD83E;
					}
				}

				p {
					font-family: Visuelt-Medium;
					font-size: 16px;
					color: #989898;
					margin-bottom: 10px;
					text-align: center;

					a {
						font-family: Visuelt-Medium;
						font-size: 16px;
						color: #989898;
						text-align: center;
					}
				}

				.badges {
					width: calc(100% - 50px);
					margin: 0 auto;
					line-height: 25px;
					text-align: center;
					margin-bottom: -5px;

					img {
						margin: 0 10px;
						height: 24px;
					}
				}
			}

			.ucet {
				margin-bottom: 40px;

				@media (min-width: 900px) {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}

				p {
					margin: 0 auto;
					width: calc(100% - 50px);
					margin-bottom: 15px;

					@media (min-width: 900px) {
						margin-left: 25px;
						width: calc(100% - 40px);
						max-width: 350px;
					}

					span {
						font-family: Visuelt-Bold;
					}

					a {
						color: black;
					}
				}

				img {
					margin-left: 25px;
					width: calc(100% - 50px);
					max-width: 200px;

					@media (min-width: 900px) {
						margin-left: 0;
						margin-right: 25px;
						max-width: 140px;
					}

					@media (min-width: 950px) {
						max-width: 180px;
					}
				}
			}

			.podpora {
				margin-bottom: 60px;
			}

			.jizZaplaceno {
				margin-bottom: 40px;

				div {
					background: #36C490;
					border-radius: 5px;
					width: fit-content;
					margin-left: 25px;
					padding: 15px 25px;

					span {
						font-family: Visuelt-Medium;
						font-size: 22px;
						color: #FFFFFF;
						vertical-align: middle;
					}

					img {
						vertical-align: middle;
						margin-left: 15px;
					}
				}
			}
		}
	}
`;


let stripePromise;

const getStripe = () => {

	if (!stripePromise) {
		if (process.env.NODE_ENV === 'development') {
			stripePromise = loadStripe("pk_test_51HoVckAhrY4CdGieSSFgmQzmAsa0ISJS9O9BKWyeriPnaqy2KRM9C7BN6uRFmQnt7FyHc2J1SSTREjvudtjkVkAj00K7KhKnVD");
		}
		
		if (process.env.NODE_ENV === 'production') {
			stripePromise = loadStripe("pk_live_51HoVckAhrY4CdGieQuflSqEUhCaVvEnqBHS2SPCYkYsnuIjBzUpFbuLYX4ACbBfgklJABBMMkOoIEbP7Y3Ie4zDc005SMIRTUR");
		}
	}

  return stripePromise
}


const PlatbaPage = ({ id }) => {
	const [order, setOrder] = useState();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true);

		// nastavení správné backend url

		let backendUrl = "";

		if (process.env.NODE_ENV === 'development') {
			backendUrl = 'http://localhost:1337'
		}
		
		if (process.env.NODE_ENV === 'production') {
			backendUrl = 'https://goodbye-backend.herokuapp.com'
		}

		// auth

		axios.post(`${backendUrl}/auth/local`, {
			identifier: 'app@goodbye.cz',
			password: 'Good.Bye123',
		})
			.then((res) => {
				axios.get(`${backendUrl}/orders/${id}`, {
					headers: {
						Authorization: `Bearer ${res.data.jwt}`
					},
				})
					.then(res => {
						setOrder(res.data)
						setLoading(false);
					})
					.catch((err) => {
						if (err.response.status === 404) {
							navigate("/objednavka/404/");
						}
					});
			});
	}, [id]);

	const redirectToCheckout = async event => {
    event.preventDefault()
    setLoading(true)
		const stripe = await getStripe();

		let domainName = "";
		let stripePrice = "";


		if(process.env.NODE_ENV === 'development') {
			domainName = 'http://localhost:8000'
			stripePrice = "price_1IxBLzAhrY4CdGierl4RP0E6"
		}
		
		if(process.env.NODE_ENV === 'production') {
			domainName = 'https://goodbye.cz'
			stripePrice = "price_1IxBLFAhrY4CdGieqGnUA6R0"
		}

    const { error } = await stripe.redirectToCheckout({
      mode: "payment",
      lineItems: [{ price: stripePrice, quantity: 1 }],
      successUrl: `${domainName}/objednavka/${id}/success`,
      cancelUrl: `${domainName}/objednavka/${id}/cancel`,
    })
    if (error) {
      console.warn("Error:", error)
      setLoading(false)
    }
  }

	
	return (
		<>
			{(order) ?
				<SEO
					title={`${order.name} (č. ${order.variableSymbol})`}
					description="Faktura k zaplacení"
					noindex={true}
				/>
			:
				<SEO
					title="Načítání"
					description="Faktura k zaplacení"
					noindex={true}
				/>
			}

			<section>
				{(order) ?
					<PlatbaPageInner>
						<div className="jumbotron">
							<h1>Děkujeme, teď už zbývá jen objednávku uhradit.</h1>
							<p>Platit můžete kartou nebo převodem. Jakmile budete mít zaplaceno, ozveme se vám co nejdříve a probereme další postup.</p>
						</div>

						<div className="body">
							<div className="right">
								{/* <div className="nabidka">
									<h3>Kremace bez obřadu</h3>
									<span className="jmeno">{`${order.name} (č. ${order.variableSymbol})`}</span>
									<ul>
										<li>
											<img src={Check} alt="check"/>
											<span>Přeprava vašeho blízkého k nám</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Vyřízení veškerých dokumentů</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Samotná kremace včetně poplatků</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Doručení urny s popelem až k vám domů</span>
										</li>
										<li>
											<img src={Check} alt="check"/>
											<span>Podpora na telefonu 24/7</span>
										</li>
									</ul>
									<span className="cena">13 900 <span>Kč (vč. DPH)</span></span>
								</div> */}
							</div>

							<div className="left">
								{(!order.isPaid) ?
									<>
										<div className="karta bublina">
											<h2>Platba kartou - 13 900 Kč</h2>
											<button disabled={loading} onClick={redirectToCheckout}>Zaplatit kartou</button>
											<p>Zabezpečená platba <a href="https://stripe.com">Stripe</a></p>
											<div className="badges">
												<img src={Mastercard} alt="Mastercard" />
												<img src={Visa} alt="Visa" />
												<img src={ApplePay} alt="ApplePay" />
												<img src={GooglePay} alt="GooglePay" />
											</div>
										</div>

										<div className="ucet bublina">
											<div>
												<h2>Nebo převodem:</h2>
												<p>Částka: <span>13 900 Kč</span><br/>Společnost: <span>Goodbye s.r.o.</span><br/>Číslo účtu: <span>5735717339/0800</span><br/>Variabilní symbol: <span>{order.variableSymbol}</span><br/>Poznámka: <span>{order.name}</span></p>
												<p>Po odeslání nám zašlete potvrzení převodu na e-mail <a href="mailto:podpora@goodbye.cz">podpora@goodbye.cz</a></p>
											</div>
											<img src={`https://api.paylibo.com/paylibo/generator/czech/image?accountNumber=5735717339&bankCode=0800&amount=13900.00&currency=CZK&vs=${order.variableSymbol}&message=${order.name}&size=200`} alt="QR Platba" className="qrPlatba" />
										</div>
									</>
								:
									<div className="jizZaplaceno bublina">
										<h2>Stav</h2>
										<div>
											<span>Zaplaceno</span>
											<img alt="Zaplaceno" src={CheckCircle} />
										</div>
									</div>
								}

								<div className="podpora">
									<SmallSupportRef />
								</div>
							</div>
						</div>
					</PlatbaPageInner>
				:
					<div className="loadingAnimationWrap">
						<img className="loadingAnimation" src={Loading} alt="Načítání" />
					</div>		
				}
			</section>
			
		</>
	);
}



export default PlatbaPage;
