import React from "react";
import styled from "styled-components";
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

import Telefon from "../assets/img/telefon.svg";



const SmallSupportRefInner = styled.div`
  @media (min-width: 900px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 404px;
  }

  .supportLeft {
    .obrazek {
      display: block !important;
      margin: 0 auto;
      margin-bottom: 13px;

      @media (min-width: 900px) {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  }

  .supportRight {
    .kDispozici {
      width: fit-content;
      margin: 0 auto;

      @media (min-width: 900px) {
        margin-left: 0;
        margin-bottom: 10px;
      }

      .greenDot {
        background: #49B25D;
        height: 10px;
        width: 10px;
        border-radius: 100%;
        animation: pulse 2s infinite;
        display: inline-block;
        vertical-align: middle;
      }

      span {
        font-family: Visuelt-Medium;
        font-size: 16px;
        color: #48B15C;
        line-height: 24px;
        vertical-align: middle;
        margin-left: 10px;
      }
    }

    .pomocnaLinka {
      width: fit-content;
      margin: 0 auto;
      margin-top: 8px;

      @media (min-width: 900px) {
        margin-left: 0;
        margin-top: 0;
      }

      img {
        height: 16px;
        vertical-align: middle;
        position: relative;
        top: -1px;
        margin-right: 10px;
      }

      a {
        vertical-align: middle;
        font-family: Visuelt-Regular;
        font-size: 16px;
        color: #000000;

        span {
          font-family: Visuelt-Bold;
          font-size: 16px;
          color: #000000;
        }
      }
    }
  }

  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.25;
    }

    100% {
      opacity: 1;
    }
  }
`;

const SmallSupportRef = ({ className }) => {
  
  return (
    <SmallSupportRefInner className={className}>
      <div className="supportLeft">
        <StaticQuery
          query={graphql`
            query {
              file(relativePath: { eq: "podpora.png" }) {
                childImageSharp {
                  fixed(width: 137, quality: 100) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img className="obrazek" alt="Naše podpora" fixed={data.file.childImageSharp.fixed} />
          )}
        />
      </div>
      <div className="supportRight">
        <div className="kDispozici">
          <div className="greenDot"></div>
          <span>K dispozici 24/7</span>
        </div>
        <div className="pomocnaLinka">
          <img src={Telefon} alt="Pomocná linka" />
          <a href="tel:315558136"><span>315 558 136</span> — Pomocná linka</a>
        </div>
      </div>
    </SmallSupportRefInner>
  );
}

export default SmallSupportRef;
