import React from "react";
import Loading from "../../assets/img/loading.svg";
import styled from "styled-components";
import SEO from "../SEO";

const NacitaniInner = styled.div`
	background: #fff;

	.loadingAnimationWrap {
		height: calc(100vh);
		position: fixed;
		width: 100%;
		background: rgb(241, 242, 243);

		img {
			margin: 0 auto;
			display: block;
			padding-top: 100px;
		}
  }
`;

const ObjednavkaLoading = () => {

	return (
		<>
      <SEO
        title="Načítání..."
        noindex={true}
      />
			<NacitaniInner>
        <div className="loadingAnimationWrap">
					<img className="loadingAnimation" src={Loading} alt="Načítání" />
				</div>
			</NacitaniInner>
		</>
	);
}

export default ObjednavkaLoading;
